<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="formType === 'ADD' ? 'Tambah Tipe Unit' : 'Ubah Tipe Unit'"
    class="px-5 py-3"
  >
    <page-loading :show="loading" />
    <form @submit.prevent="submit">
      <div class="form--group row" :class="{ 'has-error': validation.hasError('name') }">
        <label class="col-12 col-lg-3" for="nameField">Nama<span class="required">*</span></label>
        <div class="col-12 col-lg-6">
          <v-text-field
            id="nameField"
            v-model="name"
            type="text"
            name="name"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('name') }}</span>
        </div>
      </div>
      <div
        class="form--group row"
        :class="{ 'has-error': validation.hasError('electricityCapacity') }"
        v-show="electricalCapacityRule.allowed"
      >
        <label class="col-12 col-lg-3" for="electricityCapacity"
          >Kapasitas Listrik<span class="required" v-show="electricalCapacityRule.required"
            >*</span
          ></label
        >
        <div class="col-12 col-lg-6">
          <cleave
            id="electricityCapacity"
            v-model="electricityCapacity"
            :options="cleaveOptionNumber"
            class="basic--input"
            name="electricityCapacity"
            type="text"
          />
          <span class="val-error">{{ validation.firstError('electricityCapacity') }}</span>
        </div>
      </div>
      <div class="form--group row" v-show="minBuildingSizeRule.allowed">
        <label class="col-12 col-lg-3"
          >Luas bangunan<span class="required" v-show="minBuildingSizeRule.required">*</span></label
        >
        <div class="col-12 col-lg-1">
          <cleave
            v-model="minBuildingSize"
            :options="cleaveOptionNumber"
            class="basic--input"
            name="minBuildingSize"
            type="text"
            placeholder="Min"
          />
        </div>
        <div class="col-auto d-flex justify-center align-center">-</div>
        <div class="col-12 col-lg-1">
          <cleave
            v-model="maxBuildingSize"
            :options="cleaveOptionNumber"
            class="basic--input"
            name="maxBuildingSize"
            type="text"
            placeholder="Max"
          />
        </div>
      </div>
      <div
        class="form--group row mt-0"
        v-if="validation.hasError('minBuildingSize') || validation.hasError('maxBuildingSize')"
      >
        <div class="col-12 col-lg-3"></div>
        <div class="col-12 col-lg-9">
          <span class="val-error">{{ validation.firstError('minBuildingSize') }}</span>
          <span class="val-error" v-show="!validation.hasError('minBuildingSize')">{{
            validation.firstError('maxBuildingSize')
          }}</span>
        </div>
      </div>
      <div class="form--group row" v-show="minLandSizeRule.allowed">
        <label class="col-12 col-lg-3" for="nameField"
          >Luas tanah<span class="required" v-show="minLandSizeRule.required">*</span></label
        >
        <div class="col-12 col-lg-1">
          <cleave
            v-model="minLandSize"
            :options="cleaveOptionNumber"
            class="basic--input"
            name="minLandSize"
            type="text"
            placeholder="Min"
          />
        </div>
        <div class="col-auto d-flex justify-center align-center">-</div>
        <div class="col-12 col-lg-1">
          <cleave
            v-model="maxLandSize"
            :options="cleaveOptionNumber"
            class="basic--input"
            name="maxLandSize"
            type="text"
            placeholder="Max"
          />
        </div>
      </div>
      <div
        class="form--group row mt-0"
        v-if="validation.hasError('minLandSize') || validation.hasError('maxLandSize')"
      >
        <div class="col-12 col-lg-3"></div>
        <div class="col-12 col-lg-9">
          <span class="val-error">{{ validation.firstError('minLandSize') }}</span>
          <span class="val-error" v-show="!validation.hasError('minLandSize')">{{
            validation.firstError('maxLandSize')
          }}</span>
        </div>
      </div>
      <div class="form--group row" v-show="minBathroomRule.allowed">
        <label class="col-12 col-lg-3" for="nameField"
          >Kamar Mandi<span class="required" v-show="minBathroomRule.required">*</span></label
        >
        <div class="col-12 col-lg-1">
          <v-text-field
            v-model="minBathroom"
            type="text"
            name="minBathroom"
            placeholder="Min"
            outlined
            hide-details
          />
        </div>
        <div class="col-auto d-flex justify-center align-center">-</div>
        <div class="col-12 col-lg-1">
          <v-text-field
            v-model="maxBathroom"
            type="text"
            name="maxBathroom"
            placeholder="Max"
            outlined
            hide-details
          />
        </div>
      </div>
      <div
        class="form--group row mt-0"
        v-if="validation.hasError('minBathroom') || validation.hasError('maxBathroom')"
      >
        <div class="col-12 col-lg-3"></div>
        <div class="col-12 col-lg-9">
          <span class="val-error">{{ validation.firstError('minBathroom') }}</span>
          <span class="val-error" v-show="!validation.hasError('minBathroom')">{{
            validation.firstError('maxBathroom')
          }}</span>
        </div>
      </div>
      <div class="form--group row" v-show="minBedroomRule.allowed">
        <label class="col-12 col-lg-3" for="nameField"
          >Kamar tidur<span class="required" v-show="minBedroomRule.required">*</span></label
        >
        <div class="col-12 col-lg-1">
          <v-text-field
            v-model="minBedroom"
            type="text"
            name="minBedroom"
            placeholder="Min"
            outlined
            hide-details
          />
        </div>
        <div class="col-auto d-flex justify-center align-center">-</div>
        <div class="col-12 col-lg-1">
          <v-text-field
            v-model="maxBedroom"
            type="text"
            name="maxBedroom"
            placeholder="Max"
            outlined
            hide-details
          />
        </div>
      </div>
      <div
        class="form--group row mt-0"
        v-if="validation.hasError('minBedroom') || validation.hasError('maxBedroom')"
      >
        <div class="col-12 col-lg-3"></div>
        <div class="col-12 col-lg-9">
          <span class="val-error">{{ validation.firstError('minBedroom') }}</span>
          <span class="val-error" v-show="!validation.hasError('minBedroom')">{{
            validation.firstError('maxBedroom')
          }}</span>
        </div>
      </div>
      <div class="form--group row">
        <label class="col-12 col-lg-3" for="nameField">Harga<span class="required">*</span></label>
        <div class="col-12 col-lg-2">
          <cleave
            v-model="minPrice"
            :options="cleaveOptionCurrency"
            class="basic--input"
            name="minPrice"
            type="text"
            placeholder="Min"
          />
          <span class="val-error">{{ validation.firstError('minPrice') }}</span>
        </div>
        <div class="col-auto d-flex justify-center align-center">-</div>
        <div class="col-12 col-lg-2">
          <cleave
            v-model="maxPrice"
            :options="cleaveOptionCurrency"
            class="basic--input"
            name="maxPrice"
            type="text"
            placeholder="Max"
          />
          <span class="val-error">{{ validation.firstError('maxPrice') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('panoEmbedUrl') }">
        <label class="col-12 col-lg-3" for="panoEmbedUrl">Panorama URL</label>
        <div class="col-12 col-lg-6">
          <v-text-field
            id="panoEmbedUrl"
            v-model="panoEmbedUrl"
            type="text"
            name="panoEmbedUrl"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('panoEmbedUrl') }}</span>
        </div>
      </div>
      <photo-section ref="photoSection" />
      <div
        class="form--group row"
        v-show="totalFloorRule.allowed"
        :class="{ 'has-error': validation.hasError('totalFloor') }"
      >
        <label class="col-12 col-lg-3" for="totalFloor"
          >Jumlah Lantai<span class="required" v-show="totalFloorRule.required">*</span></label
        >
        <div class="col-12 col-lg-6">
          <v-text-field
            id="totalFloor"
            v-model="totalFloor"
            type="text"
            name="totalFloor"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('totalFloor') }}</span>
        </div>
      </div>
      <div
        class="form--group row"
        :class="{ 'has-error': validation.hasError('selectedFurnishingStatus') }"
        v-show="furnishingStatusRule.allowed"
      >
        <label class="col-12 col-lg-3" for="selectedFurnishingStatus"
          >Jenis Perabot<span class="required" v-show="furnishingStatusRule.required"
            >*</span
          ></label
        >
        <div class="col-12 col-lg-6">
          <multiselect
            name="selectedFurnishingStatus"
            id="selectedFurnishingStatus"
            v-model="selectedFurnishingStatus"
            :options="furnishingStatuses"
            :searchable="true"
            :close-on-select="true"
            track-by="id"
            label="name"
          />
          <span class="val-error">{{ validation.firstError('selectedFurnishingStatus') }}</span>
        </div>
      </div>
      <facilities-section />
      <div class="d-flex align-center justify-end">
        <v-btn color="primary" class="bottom--button" type="submit">
          {{ $t('project.btn.save') }}
        </v-btn>
      </div>
    </form>
  </base-material-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
const FacilitiesSection = () =>
  import('@/components/project-management/unit-type/form/facilities/facilities-section.vue');
const PhotoSection = () =>
  import('@/components/project-management/unit-type/form/photo-section.vue');
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import Multiselect from 'vue-multiselect';
import validation from 'simple-vue-validator/src/validation-bag';
require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  name: 'unit-type-form',
  mixins: [HelperMixin],
  components: { PageLoading, Multiselect, FacilitiesSection, PhotoSection },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapState({
      formType: (state) => state.unitType.form.formType,
      furnishingStatuses: (state) => state.unitType.form.furnishingStatuses,
      allowLandSize: (state) => state.unitType.form.allowLandSize,
      allowTotalFloor: (state) => state.unitType.form.allowTotalFloor,
      cleaveOptionDecimal: (state) => state.global.cleaveOptionDecimal,
      cleaveOptionCurrency: (state) => state.global.cleaveOptionCurrency,
      cleaveOptionNumber: (state) => state.global.cleaveOptionNumber,
    }),
    ...mapGetters({
      totalFloorRule: 'unitType/form/totalFloorRule',
      minLandSizeRule: 'unitType/form/minLandSizeRule',
      maxLandSizeRule: 'unitType/form/maxLandSizeRule',
      minBuildingSizeRule: 'unitType/form/minBuildingSizeRule',
      maxBuildingSizeRule: 'unitType/form/maxBuildingSizeRule',
      minBedroomRule: 'unitType/form/minBedroomRule',
      maxBedroomRule: 'unitType/form/maxBedroomRule',
      minBathroomRule: 'unitType/form/minBathroomRule',
      maxBathroomRule: 'unitType/form/maxBathroomRule',
      electricalCapacityRule: 'unitType/form/electricalCapacityRule',
      furnishingStatusRule: 'unitType/form/furnishingStatusRule',
    }),
    loading: {
      get() {
        return this.$store.state.unitType.form.loading;
      },
      set(value) {
        this.$store.commit('unitType/form/SET_LOADING', value);
      },
    },
    name: {
      get() {
        return this.$store.state.unitType.form.name;
      },
      set(value) {
        this.$store.commit('unitType/form/SET_NAME', value);
      },
    },
    minLandSize: {
      get() {
        return this.$store.state.unitType.form.minLandSize;
      },
      set(value) {
        this.$store.commit('unitType/form/SET_MIN_LAND_SIZE', value);
      },
    },
    maxLandSize: {
      get() {
        return this.$store.state.unitType.form.maxLandSize;
      },
      set(value) {
        this.$store.commit('unitType/form/SET_MAX_LAND_SIZE', value);
      },
    },
    minBuildingSize: {
      get() {
        return this.$store.state.unitType.form.minBuildingSize;
      },
      set(value) {
        this.$store.commit('unitType/form/SET_MIN_BUILDING_SIZE', value);
      },
    },
    maxBuildingSize: {
      get() {
        return this.$store.state.unitType.form.maxBuildingSize;
      },
      set(value) {
        this.$store.commit('unitType/form/SET_MAX_BUILDING_SIZE', value);
      },
    },
    electricityCapacity: {
      get() {
        return this.$store.state.unitType.form.electricityCapacity;
      },
      set(value) {
        this.$store.commit('unitType/form/SET_ELECTRICITY_CAPACITY', value);
      },
    },
    minBathroom: {
      get() {
        return this.$store.state.unitType.form.minBathroom;
      },
      set(value) {
        this.$store.commit('unitType/form/SET_MIN_BATHROOM', value);
      },
    },
    maxBathroom: {
      get() {
        return this.$store.state.unitType.form.maxBathroom;
      },
      set(value) {
        this.$store.commit('unitType/form/SET_MAX_BATHROOM', value);
      },
    },
    minBedroom: {
      get() {
        return this.$store.state.unitType.form.minBedroom;
      },
      set(value) {
        this.$store.commit('unitType/form/SET_MIN_BEDROOM', value);
      },
    },
    maxBedroom: {
      get() {
        return this.$store.state.unitType.form.maxBedroom;
      },
      set(value) {
        this.$store.commit('unitType/form/SET_MAX_BEDROOM', value);
      },
    },
    panoEmbedUrl: {
      get() {
        return this.$store.state.unitType.form.panoEmbedUrl;
      },
      set(value) {
        this.$store.commit('unitType/form/SET_PANO_EMBED_URL', value);
      },
    },
    minPrice: {
      get() {
        return this.$store.state.unitType.form.minPrice;
      },
      set(value) {
        this.$store.commit('unitType/form/SET_MIN_PRICE', value);
      },
    },
    maxPrice: {
      get() {
        return this.$store.state.unitType.form.maxPrice;
      },
      set(value) {
        this.$store.commit('unitType/form/SET_MAX_PRICE', value);
      },
    },
    totalFloor: {
      get() {
        return this.$store.state.unitType.form.totalFloor;
      },
      set(value) {
        this.$store.commit('unitType/form/SET_TOTAL_FLOOR', value);
      },
    },
    selectedFurnishingStatus: {
      get() {
        return this.$store.state.unitType.form.selectedFurnishingStatus;
      },
      set(value) {
        this.$store.commit('unitType/form/SET_SELECTED_FURNISHING_STATUS', value);
      },
    },
  },
  validators: {
    name(value) {
      return Validator.value(value).required(this.$i18n.t('errors.unitType.name.required'));
    },
    electricityCapacity(value) {
      if (this.electricalCapacityRule.required) {
        return Validator.value(value).required(
          this.$i18n.t('errors.unitType.electricityCapacity.required'),
        );
      } else {
        return Validator.value(true);
      }
    },
    minBuildingSize(value) {
      if (this.minBuildingSizeRule.required) {
        return Validator.value(value).required(
          this.$i18n.t('errors.unitType.buildingSize.required'),
        );
      } else {
        return Validator.value(true);
      }
    },
    maxBuildingSize(value) {
      if (this.maxBuildingSizeRule.required) {
        return Validator.value(value).required(
          this.$i18n.t('errors.unitType.buildingSize.required'),
        );
      } else {
        return Validator.value(true);
      }
    },
    minLandSize(value) {
      if (this.minLandSizeRule.required) {
        return Validator.value(value).required(this.$i18n.t('errors.unitType.landSize.required'));
      } else {
        return Validator.value(true);
      }
    },
    maxLandSize(value) {
      if (this.maxLandSizeRule.required) {
        return Validator.value(value).required(this.$i18n.t('errors.unitType.landSize.required'));
      } else {
        return Validator.value(true);
      }
    },
    minBathroom(value) {
      if (this.minBathroomRule.required) {
        return Validator.value(value).required(this.$i18n.t('errors.unitType.bathroom.required'));
      } else {
        return Validator.value(true);
      }
    },
    maxBathroom(value) {
      if (this.maxBathroomRule.required) {
        return Validator.value(value).required(this.$i18n.t('errors.unitType.bathroom.required'));
      } else {
        return Validator.value(true);
      }
    },
    minBedroom(value) {
      if (this.minBedroomRule.required) {
        return Validator.value(value).required(this.$i18n.t('errors.unitType.bedroom.required'));
      } else {
        return Validator.value(true);
      }
    },
    maxBedroom(value) {
      if (this.maxBedroomRule.required) {
        return Validator.value(value).required(this.$i18n.t('errors.unitType.bedroom.required'));
      } else {
        return Validator.value(true);
      }
    },
    minPrice(value) {
      return Validator.value(value).required(this.$i18n.t('errors.unitType.price.required'));
    },
    maxPrice(value) {
      return Validator.value(value).required(this.$i18n.t('errors.unitType.price.required'));
    },
    totalFloor(value) {
      if (this.totalFloorRule.required) {
        return Validator.value(value).required(this.$i18n.t('errors.unitType.totalFloor.required'));
      } else {
        return Validator.value(true);
      }
    },
    selectedFurnishingStatus(value) {
      if (this.furnishingStatusRule.required) {
        return Validator.value(value).required(this.$i18n.t('errors.unitType.selectedFurnishingStatus.required'));
      } else {
        return Validator.value(true);
      }
    },
  },
  methods: {
    validate() {
      let validationArray = [];
      validationArray.push(this.$refs.photoSection.validate());
      validationArray.push(this.$validate());
      return Promise.all(validationArray).then(function (results) {
        if (
          results.filter(function (result) {
            console.log('WHICH SECTION FAILED: ', result);
            return !result;
          }).length === 0
        ) {
          console.log('ALL VALID!');
          return true;
        }
        console.log('SOME INVALID!');
        return false;
      });
    },
    async submit() {
      if (await this.validate()) {
        await this.$store.dispatch('unitType/form/postData');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('project.publish.successMsg'),
          'success',
        );
        this.$router.back();
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>

<style scoped></style>
